<template>
  <div class="">
    Authenticate
  </div>
</template>

<script>



			// let originEncoded = encodeURIComponent(document.location.origin);;

			// this.$session.set('token', null);
			// this.$session.set('google_user_email', null);
			// this.$session.set('google_user_first_name', null);
			// this.$session.set('google_user_last_name', null);
			// this.$session.set('google_user_gender', null);
			// this.$session.set('google_user_image', null);
			// this.$session.set('google_auth_url', 'https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=online&client_id=31381570039-mqbi4gatl1te41jhc1l7uic3c9rs7t1o.apps.googleusercontent.com&redirect_uri=' + originEncoded +'&state&scope=email%20profile&approval_prompt=auto');
		
export default {
  name: "AuthenticateMain",
  components: {
  },

  /**
   * TODO: figrue out how to authenticate without PHP processing the token
   */
  mounted() {

    // http://localhost:8080/authenticate?code=4%2F0AX4XfWh216QmLODGEIvdi2s8gicbXU_U0ghPLV_0N0NpZalclSKFcy3N-VoQM0fV6fDDrw&scope=email+profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+openid+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&authuser=0&hd=appcropolis.com&prompt=none
    /** remove port number if running on NODE JS */
    // let handler = document.location.origin.replace(/\:\d+$/, '') + document.location.pathname + document.location.search;
  }
};
</script>
